$font-family-sans-serif: 'PT Sans', sans-serif;
$font-family-serif: 'PT Sans', sans-serif;

$color-reforma-gray: #d1d2d4;

$brand-primary: #f26522;

$btn-border-radius: 15px;

@import "node_modules/bootstrap/scss/bootstrap-flex";


.uvlaka-bold
{
  font-weight: bold;

}

.dupla-dupla-bold
{
  font-weight: bold;
  margin-left: $font-size-base * 2;
}

.navbar
{
  background: black;
  border-radius: 0;

  .nav-link
  {
    color: white;
    text-transform: lowercase;
    font-weight: bold;
  }
}

.navbar-transperent
{
  background: transparent;

  .nav-link
  {
    color: black;
  }
}


.article-heading
{
  background: $color-reforma-gray;
  margin: 0;

  *
  {
    margin: 0;
    font-size: 20px !important;
  }
}

.article
{
  font-size: 16px;
  font-weight: bold;
}

.other-content
{
  font-size: 16px;

  margin-top: 20px;

  h1,h2,h3,h4,h5,h6
  {
    font-weight: bold;
  }

  *
  {
    font-size: 16px !important;
  }
}

.login-for-content
{
  margin-bottom: 2 * $spacer;
}

.section-title
{
  font-size: 22px;
}

.card
{
  border-radius: 15px;
}

.card-header
{
  background: black;
  *
  {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
}

.card-header:first-child {
  border-radius: 15px 15px 0 0;
}

.form-control {
  border-radius: 15px;
}

.btn
{
  font-weight: bold;
}

.navigation
{
  .navigation-header
  {
    padding: 10px;
    border-top-right-radius: 15px;
    color: white;

    h2
    {
      font-size: 20px;
      font-weight: bold;
      padding-left: 5px;
      margin: 0;
    }
    background: #4075a1;
  }

  .navigation-contents
  {
    padding: 0 10px ;
   // max-height: 350px;
    overflow-y: auto;
  }

  .active
  {
    background: $color-reforma-gray;
  }

  .navigation-section-title
  {
    font-weight: bold;
    margin: 0;
  }

  a:focus, a:hover {
    text-decoration:none;
  }

  .navigation-articles
  {
    padding-left: 10px;

    .navigation-articles--title
    {
      display: block;
      color: black;

      &:hover, &:active, &:focus
      {
        background: $color-reforma-gray;
      }
    }
  }
}

.pointable
{
  cursor: pointer;
}

[uib-typeahead-popup].dropdown-menu {
  padding-left: 10px;
  padding-right: 10px;

  border-color: #4075a1;

  a
  {
    color: black;
    padding-bottom: 5px;
  }
}

.terms
{
  h2
  {
    font-size: 20px;
  }
}